h1 {
    justify-content: center;
    text-align: center;
}
.login_container {
    margin: auto;
    width: 40%;

    .form_field {
        margin: 10px 10px;
    }
    .form_button {
        margin: 10px 10px;
        position: relative;
    }
    .general_error {
        color: red;
        margin: auto;
    }
    .form_spinner {
        position: absolute;
    }
}
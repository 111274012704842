
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .drawer {  
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
    position: fixed;
    height:100%;
    width: 100%;
    margin: auto;
    margin-top: -25px;
    z-index: 999;
    background-color: white;
  }
  
  .drawer-close {
    display: none;
  }
  .drawer-start {
    visibility: hidden;
  }

  .nav-part3 {
    display: flex;
    flex-direction: column;
    .nav-button1 {
      font-size: x-large;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

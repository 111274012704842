.company_card {
    display: flex;
    flex-direction: column;
    width: 40%;
    
    .trailer_img {
        width: 100px;
        height: 100px;
        img {
            width: inherit;
            height: inherit;
        }
    }
    .trailer_details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        

        h3 {
            order: 1;
            align-self: center;
        }
        h2 {
            order: 2;
            color: green;
        }
    }
}
.image_trailer {
    width: 10px;
    height: 250px;
}
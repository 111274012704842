
.title {
    display: flex;
    flex-direction: column;
    padding:0 20px;
    p {
        display: flex;
        float: right;
        align-self: flex-end;
        margin-top: 0;
        margin-bottom: 0;
    }
    h1 {
        border-top: 5px solid #3f51b5;
        border-bottom: 5px solid #3f51b5;
    }
}

.mileage-label {
    font-size: 36px;
}
.mileage-icon {
    font-size:36px; color:#3f51b5;
}
.truck-image {
    max-width: 100%;
    height: 500px;
    justify-content: center;
    display: flex;
    justify-content: center;
    img {
        width: inherit;
        height: inherit;
    }
}
.primary-facts {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    div {
        padding: 10px;
        border: solid 5px #3f51b5;
        border-radius: 10px;
        span {
            font-style:italic
        }
    }
}

.main {
    display: flex;
    padding-top: 20px;
    .truck-image1 {
        flex: 1;
        // max-width: 100%;
        max-height: 500px; 
        text-align: center;
        img {
            max-width: inherit;
            max-height: inherit;
        }
    }
    .truck-data {
        padding: 0 40px 0 40px;
        flex:1;
        display: flex;
        flex-direction: column;
        .title1 {
            float: right;
        }
        .model {

        }
        .truck-facts {
            display: flex;
            .truck-facts-label {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
            .truck-facts-value {
                display: flex;
                flex-direction: column;
                flex:1;
            }
        }
    }
}

@media screen and (max-width: 784px) {
    .main {
        display: flex;
        flex-direction: column;
        .truck-data {
            width: inherit;
            height: fit-content;
            padding-bottom: 40px;
        }
        .truck-image1 {
            img {
                max-width: 100%
            }
        }
    }
}
